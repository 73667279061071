import React, { FC } from 'react'
import styles from "../../assets/styles/dashboard.module.css"
import userImg from "../../assets/images/team/1.png"
interface PropTypes {
    patientDetails?: AppointmentDetailsResponse['patientDetails'];
    healthDetails?: AppointmentDetailsResponse['healthDetails'];
}
const UserScore: FC<PropTypes> = ({ patientDetails, healthDetails }) => {
    return (
        <>
            {
                patientDetails &&
                <div className={styles.generalDetailsSec}>
                    <div className={styles.generalDetailsMain}>
                        <div className={styles.userImgAndName}>
                            <div className={styles.userImg}>
                                <img src={patientDetails?.profilePic || userImg} alt="user Image" />
                            </div>
                            <div className={styles.userDetails}>
                                <h3>{patientDetails?.name}</h3>
                                <p>Patient ID: {patientDetails?.uniquePatientId}</p>
                            </div>
                        </div>
                        <div className={styles.GOtherSec}>
                            <h4>Age</h4>
                            <p>{patientDetails?.age}</p>
                        </div>
                        <div className={styles.GOtherSec}>
                            <h4>Date of birth</h4>
                            <p>{patientDetails?.dateofbirth}</p>
                        </div>
                        <div className={styles.GOtherSec}>
                            <h4>Gender</h4>
                            <p>{patientDetails?.gender}</p>
                        </div>
                        <div className={styles.GOtherSec}>
                            <h4>Location</h4>
                            <p>{patientDetails?.location}</p>
                        </div>
                        <div className={styles.GOtherSec}>
                            <h4>Blood Group</h4>
                            <p>{patientDetails?.bloodGroup}</p>
                        </div>
                    </div>
                </div>
            }
            <div className={styles.ScoreTabSec}>
                <div className="grid grid-cols-2 md:grid-cols-8 xl:grid-cols-12 2xl:gap-4 xl:gap-2 gap-4 gap-y-4">
                    {healthDetails &&
                        Object.keys(healthDetails).map((key, index) => {
                            const value = healthDetails[key as keyof AppointmentDetailsResponse['healthDetails']];
                            return (
                                <div
                                    className={`${index === 0 ? `col-span-3 ${styles.ActiveScoreTab}` : index === 4 ? 'col-span-3' : 'col-span-2'} ${styles.ScoreTab}`}
                                    key={index}
                                >
                                    <h4>{key.replace(/([A-Z])/g, ' $1')}</h4>
                                    <p>{value}</p>
                                </div>
                            );
                        })}
                </div>
            </div>
        </>
    );
};

export default UserScore