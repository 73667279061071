import React, { FC } from 'react'
import styles from "../../assets/styles/appointments.module.css";
import { toTitleCase } from '../../utils/functions/common';
interface PropTypes {
    connectCallLog: AppointmentDetailsResponse['connectCallLog'] | undefined;
}
const ChiefComplaints: FC<PropTypes> = ({ connectCallLog }) => {
    return (
        <div>
            {connectCallLog &&
                Object.keys(connectCallLog).map((key, index) => {
                    const value = connectCallLog[key as keyof AppointmentDetailsResponse['connectCallLog']];
                    const formattedKey = key
                        .replace(/_/g, ' ')
                        .replace(/\b\w/g, char => char.toUpperCase());
                    return (
                        <React.Fragment key={index}>
                            {
                                value &&
                                <div
                                    className={styles.cheifCoHeadDes}
                                >
                                    <h4>{formattedKey}</h4>
                                    <p>{value}</p>
                                </div>
                            }
                        </React.Fragment>
                    );
                })}
        </div>
    )
}

export default ChiefComplaints