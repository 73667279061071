import React, { useEffect, useState } from 'react';
import CustomContainer from "../../components/common/CustomContainer";
import styles from "../../assets/styles/profile.module.css";
import avtarIcon from "../../assets/images/common/avtar.png";
import InputFile from "../../components/common/InputFile";
import { Autocomplete, FormGroup, FormLabel, MenuItem, Select, TextareaAutosize, TextField } from "@mui/material";
import { handleKeyPress } from "../../utils/functions/common";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../utils/store';
import { EducationsReducer, ExperiencesReducer, getCiyReducer, getStateReducer, LanguagesReducer, OrganizationsReducer, SpecialitiesReducer } from '../../utils/store/profile/ProfileSlice';
import { getProfileApi, updateProfileApi, uploadFileApi } from '../../utils/api/services';
import { toast } from 'react-toastify';
import BtnLoader from '../../components/common/BtnLoader';
import Loader from '../../components/common/Loader';
import { setIsProfileComplete } from '../../utils/store/auth/AuthSlice';
import { AddMoreIcon, DeleteIcon, FileIcon, SearchIcon } from '../../utils/functions/Icons';
import AutocompleteInput from '../../components/common/AutocompleteInput';

const Profile: React.FC = () => {
    const [btnLoading, setBtnLoading] = useState(false)
    const [formState, setFormState] = useState<DoctorProfileState>({
        name: "",
        profilePic: "",
        reg_no: "",
        dateOfBirth: "",
        phoneNo: "",
        gender: "",
        signature: "",
        address: "",
        city: "",
        state: "",
        speciality: [],
        experience: '',
        biography: "",
        languages: [],
    })
    const [certificatesData, setCertificatesData] = useState<CertificateData[]>([
        {
            degreeName: '',
            organization: '',
            degreeDocs: '',
        },
    ]);

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const { stateData, cityData, master } = useSelector((state: RootState) => state.profile);
    const { LoginData } = useSelector((state: RootState) => state.auth)

    const handleCertificateAddMore = () => {
        setCertificatesData((prev: CertificateData[]) => {
            if (prev.every((item: CertificateData) => item.degreeName !== '' && item.degreeDocs !== '' && item.organization)) {
                return [...prev, {
                    degreeName: '',
                    organization: '',
                    degreeDocs: '',
                }];
            }
            return prev;
        });
    };
    const handleDeleteCirtificate = (id: number) => {
        if (certificatesData?.length > 1) {
            const _Date = certificatesData?.filter((item, index) => {
                return index !== id
            })
            setCertificatesData(_Date)
        }
    }
    const handleCerFormChange = (index: number, fieldName: keyof CertificateData, value: string) => {
        setCertificatesData((prev) => {
            const newData = [...prev];
            (newData[index] as any)[fieldName] = value;
            return newData;
        });
    };

    const handleCerFileChange = (name: string, file: File | null, index?: number) => {
        if (file && index !== undefined) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64data = reader.result;
                if (typeof base64data === 'string') {
                    setCertificatesData((prev) => {
                        let _name = name as keyof CertificateData
                        const newData = [...prev];
                        (newData[index as number] as any)[_name] = base64data;
                        newData[index as number]['degreeDocsChange'] = true;
                        return newData;
                    });
                }
            };
            reader.onerror = () => {
                console.error('There was an error reading the file!');
            };

            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        dispatch(EducationsReducer({ path: 'educations' }));
        dispatch(SpecialitiesReducer({ path: 'specialities' }));
        dispatch(ExperiencesReducer({ path: 'experiences' }));
        dispatch(LanguagesReducer({ path: 'languages' }));
        dispatch(OrganizationsReducer({ path: 'organizations' }));
        dispatch(getStateReducer());
    }, [dispatch])
    const fetchProfileData = async (id: number) => {
        try {
            setLoading(true)
            const result = await getProfileApi(id);
            if (result?.success) {
                setLoading(false)
                const profileData = result?.doctor;
                if (profileData) {
                    setFormState((prev) => ({
                        ...prev,
                        name: profileData.name ? profileData.name : '',
                        profilePic: profileData.profilePic ? profileData.profilePic : '',
                        reg_no: profileData.reg_no ? profileData.reg_no : '',
                        dateOfBirth: profileData.dateOfBirth ? profileData.dateOfBirth : '',
                        phoneNo: profileData.phoneNo ? profileData.phoneNo : '',
                        gender: profileData.gender ? profileData.gender : '',
                        signature: profileData.signature ? profileData.signature : '',
                        signatureChange: false,
                        address: profileData.address ? profileData.address : '',
                        city: profileData.city ? profileData.city : '',
                        state: profileData.state ? profileData.state : '',
                        speciality: profileData?.speciality ? profileData?.speciality : [],
                        experience: profileData.experience ? profileData.experience : '',
                        biography: profileData.description ? profileData.description : '',
                    }));
                    setCertificatesData((prev) => {
                        if (profileData.educationCertificates) {
                            return profileData.educationCertificates.map((item: any) => {
                                return {
                                    degreeName: item.degreeName ? item.degreeName : '',
                                    organization: item.organization ? item.organization : '',
                                    degreeDocs: item.degreeDocs ? item.degreeDocs : '',
                                    degreeDocsChange: false,
                                }
                            })
                        } else {
                            return prev;
                        }
                    });
                    setFormState((prev) => ({
                        ...prev,
                        languages: profileData.languages ? profileData.languages.map((item: any) => item.language ? item.language : item) : [],
                    }));
                }
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    useEffect(() => {
        if (LoginData?.id) {
            fetchProfileData(LoginData?.id);
        }
    }, []);

    const handleAutoComplete = (name: keyof DoctorProfileState, value: any) => {
        setFormState((prev) => ({ ...prev, [name]: value }));
    };

    const handleInputChange: HandleInputChangeType = (e) => {
        const { name, value } = e.target;

        setFormState((prev: DoctorProfileState) => ({ ...prev, [name as keyof DoctorProfileState]: value }));
    };
    const handleSelectChange: HandleSelectChangeType = (e) => {
        const { name, value } = e.target;
        setFormState((prev: DoctorProfileState) => ({ ...prev, [name as keyof DoctorProfileState]: value }));
        if (name === "state") {
            const stateCode = stateData?.filter((item) => item?.state === value)
            if (stateCode?.length > 0) {
                dispatch(getCiyReducer({ state_id: stateCode[0]?.id }))
            }
        }
    };

    const handleDateChange: HandleDateChangeType = (date, name) => {
        const formattedDate = moment(date).format('DD-MM-YYYY');
        setFormState((prev: DoctorProfileState) => ({ ...prev, [name as keyof DoctorProfileState]: formattedDate }));
    };

    const handleFileChange = (name: string, file: File | null, index?: number) => {
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64data = reader.result;
                if (typeof base64data === 'string') {
                    if (name === 'signature') {
                        setFormState((prev) => ({ ...prev, signature: base64data, signatureChange: true }));
                    } else {
                        setFormState((prev) => ({ ...prev, [name]: base64data, imgChange: true }));
                    }
                }
            };

            reader.onerror = () => {
                console.error('There was an error reading the file!');
            };

            reader.readAsDataURL(file);
        }
    };

    const RemoveProfileImg = (index?: number) => {
        setFormState((prev) => ({ ...prev, profilePic: '' }))
    }
    const RemoveSignatureImg = () => {
        setFormState((prev) => ({ ...prev, signature: '' }))
    }

    const fetchApiUrl = async (Url: string, type: string) => {
        try {
            const result = await uploadFileApi({ file: Url, type: type })
            if (result?.success) {
                return result?.fileUrl
            }
        } catch (error: any) {
            toast.error(error?.data?.errors || error?.message);
        }
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault()
            if (LoginData?.id) {
                const PtImg = (formState?.imgChange && formState?.profilePic) && await fetchApiUrl(formState?.profilePic, 'patient');
                const signatureImg = (formState?.signatureChange && formState?.signature) && await fetchApiUrl(formState?.signature, 'patient');
                const degreeDocs = certificatesData.map(async (item) => {
                    if (item.degreeDocsChange && item.degreeDocs) {
                        const degreeDocUrl = await fetchApiUrl(item.degreeDocs, 'patient');
                        return {
                            degreeName: item.degreeName,
                            organization: item.organization,
                            degreeDocs: degreeDocUrl?.fileUrl,
                        }
                    } else {
                        return {
                            degreeName: item.degreeName,
                            organization: item.organization,
                            degreeDocs: item.degreeDocs,
                        }
                    }
                });

                const degreeDocsResult = await Promise.all(degreeDocs);

                const sendData = {
                    doctorData: {
                        name: formState.name,
                        profilePic: PtImg?.fileUrl || formState.profilePic,
                        reg_no: formState.reg_no,
                        dateOfBirth: formState.dateOfBirth,
                        phoneNo: formState.phoneNo,
                        gender: formState.gender,
                        signature: signatureImg?.fileUrl || formState.signature,
                        address: formState.address,
                        city: formState.city,
                        state: formState.state,
                        speciality: JSON.stringify(formState.speciality?.map((item: any) => item?.speciality_name ? item?.speciality_name : item)),
                        experience: formState.experience,
                        biography: formState.biography,
                    },
                    certificatesData: degreeDocsResult,
                    languages: formState.languages?.map((item: any) => item?.languageName ? item?.languageName : item),
                };
                setBtnLoading(true);
                const result = await updateProfileApi(sendData, LoginData?.id);
                if (result?.success) {
                    setBtnLoading(false)
                    fetchProfileData(LoginData?.id)
                    toast.success(result?.message)
                }
                else {
                    setBtnLoading(false)
                    toast.error(result?.message)
                }
            }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }


    if (loading) {
        return <Loader />
    }

    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <form noValidate={false} onSubmit={handleSubmit}>
                    <div className={'HeadAndButton'}>
                        <h2>Doctor Profile</h2>
                    </div>
                    <div className="grid grid-cols-1 xl:grid-cols-4 gap-4">
                        <section className={styles.ProfileImageSec}>
                            <div className={styles.profileImg}>
                                <img src={formState?.profilePic ? formState?.profilePic : avtarIcon} alt='Patient Image' />
                            </div>
                            <div className={styles.chooseProfileAndRemove}>
                                <InputFile
                                    title="Choose profile"
                                    colorBorder={true}
                                    id={"profile_Pic"}
                                    name={"profilePic"}
                                    onlyImg={true}
                                    handleFileChange={handleFileChange}
                                />
                                <h4 onClick={() => RemoveProfileImg()}>Remove</h4>
                            </div>
                        </section>
                        <section className={`xl:col-span-3 ${styles.ProfileDetailsSec}`}>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-y-6 gap-x-16">
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Name <span className='errorMsg'>*</span></FormLabel>
                                    <TextField
                                        type='text'
                                        className={`custumTextField profileInput`}
                                        name="name"
                                        required
                                        placeholder='Enter your name'
                                        value={formState.name ?? ''}
                                        onChange={handleInputChange}
                                        onKeyPress={(event) => {
                                            if (!/[a-zA-Z\s]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Email<span className='errorMsg'>*</span></FormLabel>
                                    <TextField
                                        type='email'
                                        className={`custumTextField profileInput`}
                                        name="email"
                                        disabled
                                        value={LoginData?.email}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Reg. Number<span className='errorMsg'>*</span></FormLabel>
                                    <TextField
                                        type="text"
                                        className={`custumTextField profileInput`}
                                        name="reg_no"
                                        required
                                        placeholder='Enter your reg. number'
                                        value={formState.reg_no}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Contact Number <span className='errorMsg'>*</span></FormLabel>
                                    <TextField
                                        type="text"
                                        className={`custumTextField profileInput`}
                                        name="phoneNo"
                                        required
                                        placeholder='Enter your mobile'
                                        value={formState.phoneNo}
                                        inputProps={{
                                            inputMode: 'numeric',
                                            maxLength: 10,
                                        }}
                                        onKeyPress={handleKeyPress}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Date of Birth <span className='errorMsg'>*</span></FormLabel>
                                    <DatePicker
                                        className="custumTextField profileInput"
                                        value={moment(formState.dateOfBirth, 'DD-MM-YYYY')}
                                        maxDate={moment(new Date(), 'DD-MM-YYYY')}
                                        format="DD-MM-YYYY"
                                        onChange={(date) => handleDateChange(moment(date)?.toDate(), 'dateOfBirth')}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Gender</FormLabel>
                                    <Select
                                        className={`custumTextField profileInput`}
                                        placeholder="Select gender"
                                        value={formState.gender}
                                        name='gender'
                                        onChange={handleSelectChange}
                                    >
                                        <MenuItem value="Male">Male</MenuItem>
                                        <MenuItem value="Female">Female</MenuItem>
                                        <MenuItem value="Others">Others</MenuItem>
                                    </Select>
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Address <span className='errorMsg'>*</span></FormLabel>
                                    <TextField
                                        type="text"
                                        className={`custumTextField profileInput`}
                                        name="address"
                                        placeholder='Enter your address'
                                        required
                                        value={formState?.address}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">State</FormLabel>
                                    <Select
                                        className={`custumTextField profileInput`}
                                        placeholder="Select state"
                                        value={formState?.state}
                                        name='state'
                                        onChange={handleSelectChange}
                                    >
                                        {
                                            stateData?.length > 0 && stateData?.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item?.state}>{item?.state}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">City</FormLabel>
                                    <Select
                                        className={`custumTextField profileInput`}
                                        placeholder="Select city"
                                        value={formState?.city}
                                        name='city'
                                        disabled={formState?.state !== "" ? false : true}
                                        onChange={handleSelectChange}
                                    >
                                        {
                                            cityData?.length ===0 && 
                                            <MenuItem value={formState?.city}>{formState?.city}</MenuItem>
                                        }
                                        {
                                            cityData?.length > 0 && cityData?.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item?.city}>{item?.city}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormGroup>
                            </div>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-y-6 md:gap-6 mt-6'>
                                <FormGroup className="customFormGroup md:col-span-2">
                                    <FormLabel className="customFormLabel">Language<span className='errorMsgInput'>*</span> </FormLabel>
                                    <AutocompleteInput
                                        options={master?.languages}
                                        multiple={true}
                                        // required={true}
                                        className="custumTextField profileInput"
                                        value={formState?.languages}
                                        getOptionLabel={(option) => typeof option === 'string' ? option : option?.languageName}
                                        getOptionKey={(option) => typeof option === 'string' ? option : option.languageName}
                                        createOption={(inputValue: string) => {
                                            return { languageName: inputValue };
                                        }}
                                        onSelect={(value) => handleAutoComplete('languages', value)}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Speciality<span className='errorMsgInput'>*</span> </FormLabel>
                                    <AutocompleteInput
                                        options={master?.specialities}
                                        multiple={true}
                                        // required={true}
                                        className="custumTextField profileInput"
                                        value={formState?.speciality}
                                        getOptionLabel={(option) => typeof option === 'string' ? option : option?.speciality_name}
                                        getOptionKey={(option) => typeof option === 'string' ? option : option.speciality_name}
                                        createOption={(inputValue: string) => {
                                            return { speciality_name: inputValue };
                                        }}
                                        onSelect={(value) => handleAutoComplete('speciality', value)}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Experience<span className='errorMsgInput'>*</span> </FormLabel>
                                    <Autocomplete
                                        freeSolo
                                        options={master?.experiences}
                                        className="custumTextField profileInput"
                                        value={formState?.experience}
                                        getOptionLabel={(option) => typeof option === 'string' ? option : option.yearsOfExperience}
                                        getOptionKey={(option) => typeof option === 'string' ? option : option.yearsOfExperience}
                                        filterOptions={(options, { inputValue }) => {
                                            return options?.filter((option) => {
                                                const values = Object.values(option);
                                                return values.some((value) => {
                                                    return typeof value === 'string' && value.toLowerCase().includes(inputValue.toLowerCase());
                                                });
                                            });
                                        }}
                                        onInputChange={(event, value, reason) => {
                                            if (reason === 'input') {
                                                setFormState((prev) => ({ ...prev, experience: value }));
                                            }
                                        }}
                                        onChange={(event, value) => {
                                            if (typeof value === 'string') {
                                                setFormState((prev) => ({ ...prev, experience: value }));
                                            } else if (value !== null) {
                                                setFormState((prev) => ({ ...prev, experience: value.yearsOfExperience }));
                                            } else {
                                                setFormState((prev) => ({ ...prev, experience: '' }));
                                            }
                                        }}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter') {
                                                event.preventDefault();
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <div className="flex items-center">
                                                <TextField {...params} className="autocompleteInputStyle" />
                                                <span className="searchIconOnAutocomplete">
                                                    <SearchIcon />
                                                </span>
                                            </div>
                                        )}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup md:col-span-2">
                                    <FormLabel className="customFormLabel">Signature<span className='errorMsgInput'>*</span> </FormLabel>
                                    <InputFile
                                        title="Choose signature"
                                        colorBorder={true}
                                        id={"signature_image"}
                                        name={"signature"}
                                        onlyImg={true}
                                        handleFileChange={handleFileChange}
                                        fullWidth={true}
                                        handleRemoveFile={RemoveSignatureImg}
                                        previewUrl={formState?.signature}
                                    />
                                </FormGroup>
                            </div>
                            <div className='flex items-end gap-6 w-full'>
                                <div className='mt-6 w-full'>
                                    {
                                        certificatesData?.map((item, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div className={styles.certificateHead}>
                                                        <h3>Certificate {index + 1}  </h3>
                                                        {
                                                            certificatesData?.length > 1 &&
                                                            <button type='button' title='Remove this certificate' onClick={() => handleDeleteCirtificate(index)}> <DeleteIcon /> </button>
                                                        }
                                                    </div>
                                                    <div className='grid grid-cols-1 md:grid-cols-2 gap-6 mt-6 w-full'>
                                                        <FormGroup className="customFormGroup">
                                                            <FormLabel className="customFormLabel">Education/ Degree</FormLabel>
                                                            <Autocomplete
                                                                freeSolo
                                                                options={master?.educationData}
                                                                className="custumTextField profileInput"
                                                                value={item?.degreeName}
                                                                getOptionLabel={(option) => typeof option === 'string' ? option : option.degreeName}
                                                                getOptionKey={(option) => typeof option === 'string' ? option : option.degreeName}
                                                                filterOptions={(options, { inputValue }) => {
                                                                    return options?.filter((option) => {
                                                                        const values = Object.values(option);
                                                                        return values.some((value) => {
                                                                            return typeof value === 'string' && value.toLowerCase().includes(inputValue.toLowerCase());
                                                                        });
                                                                    });
                                                                }}
                                                                onInputChange={(event, value, reason) => {
                                                                    if (reason === 'input') {
                                                                        handleCerFormChange(index, 'degreeName', value)
                                                                    }
                                                                }}
                                                                onChange={(event, value) => {
                                                                    if (typeof value === 'string') {
                                                                        handleCerFormChange(index, 'degreeName', value)
                                                                    } else if (value !== null) {
                                                                        handleCerFormChange(index, 'degreeName', value?.degreeName)
                                                                    } else {
                                                                        handleCerFormChange(index, 'degreeName', '')
                                                                    }
                                                                }}
                                                                onKeyDown={(event) => {
                                                                    if (event.key === 'Enter') {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                renderInput={(params) => (
                                                                    <div className="flex items-center">
                                                                        <TextField {...params} className="autocompleteInputStyle" />
                                                                    </div>
                                                                )}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup className="customFormGroup">
                                                            <FormLabel className="customFormLabel">Organization</FormLabel>
                                                            <Autocomplete
                                                                freeSolo
                                                                options={master?.organizations}
                                                                className="custumTextField profileInput"
                                                                value={item?.organization}
                                                                getOptionLabel={(option) => typeof option === 'string' ? option : option.organizationName}
                                                                getOptionKey={(option) => typeof option === 'string' ? option : option.organizationName}
                                                                filterOptions={(options, { inputValue }) => {
                                                                    return options?.filter((option) => {
                                                                        const values = Object.values(option);
                                                                        return values.some((value) => {
                                                                            return typeof value === 'string' && value.toLowerCase().includes(inputValue.toLowerCase());
                                                                        });
                                                                    });
                                                                }}
                                                                onInputChange={(event, value, reason) => {
                                                                    if (reason === 'input') {
                                                                        handleCerFormChange(index, 'organization', value)
                                                                    }
                                                                }}
                                                                onChange={(event, value) => {
                                                                    if (typeof value === 'string') {
                                                                        handleCerFormChange(index, 'organization', value)
                                                                    } else if (value !== null) {
                                                                        handleCerFormChange(index, 'organization', value?.organizationName)
                                                                    } else {
                                                                        handleCerFormChange(index, 'organization', '')
                                                                    }
                                                                }}
                                                                onKeyDown={(event) => {
                                                                    if (event.key === 'Enter') {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                renderInput={(params) => (
                                                                    <div className="flex items-center">
                                                                        <TextField {...params} className="autocompleteInputStyle" />
                                                                    </div>
                                                                )}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup className="customFormGroup md:col-span-2 w-full">
                                                            <FormLabel className="customFormLabel">Upload Certificates</FormLabel>
                                                            <InputFile
                                                                title="Choose Certificates"
                                                                colorBorder={true}
                                                                id={`degreeDocs${index}`}
                                                                name={"degreeDocs"}
                                                                onlyImg={true}
                                                                handleFileChange={handleCerFileChange}
                                                                index={index}
                                                                fullWidth={true}
                                                                handleRemoveFile={RemoveSignatureImg}
                                                                previewUrl={item?.degreeDocs}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </div>
                                <button type='button' className={styles.AddMoreBtn} onClick={handleCertificateAddMore}>
                                    <AddMoreIcon />
                                </button>
                            </div>
                            <div className='grid grid-cols-1 gap-6 mt-6 w-full'>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Brief description</FormLabel>
                                    <TextareaAutosize
                                        minRows={2}
                                        className={`custumTextField profileInput`}
                                        name='biography'
                                        placeholder='Brief description'
                                        value={formState?.biography}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </div>
                            <button className='fillButton mt-8' disabled={btnLoading}> {btnLoading && <BtnLoader />} Save profile</button>
                        </section>
                    </div>
                </form>
            </div>
        </CustomContainer>
    );
};

export default Profile;