import React, { FC, useEffect, useState } from 'react'
import styles from "../../assets/styles/videocall.module.css"
import { DocIcon, HeartIcon, RxIcon, ToggleIcon } from '../../utils/functions/Icons'
import GeneratePrescription from './GeneratePrescription'
import VitalMonitoring from './VitalMonitoring'
import Documents from './Documents'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../utils/store'
import { handleVideoCallActiveTab } from '../../utils/store/common/CommonSlice'
import { AppointmentDetaisApi, AppointmentDetaisByPatientIdApi } from '../../utils/api/services'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom'
interface PropsType {
    TalkToDoctorData: WaitingListItem | null;
    appointmentDetails: AppointmentDetailsResponse | null
}
const VideoCallSidebar: FC<PropsType> = ({ TalkToDoctorData, appointmentDetails }) => {
    // const [appointmentDetails, setAppointmentDetails] = useState<AppointmentDetailsResponse | null>(null);
    // const [loading, setLoading] = useState(false);
    const { videoCallActiveTab } = useSelector((state: RootState) => state.common)
    const dispatch = useDispatch<AppDispatch>()

    const location = useLocation();

    // const fetchAppointmentDetails = async (apId: number | null, ptId: number) => {
    //     try {
    //         setLoading(true);
    //         const result = (apId && ptId) ? await AppointmentDetaisApi(apId, ptId) : await AppointmentDetaisByPatientIdApi(ptId);
    //         if (result?.success) {
    //             setLoading(false);
    //             setAppointmentDetails(result?.response);
    //         } else {
    //             setLoading(false);
    //         }
    //     } catch (error: any) {
    //         setLoading(false);
    //         toast.error(error?.data?.errors || error?.message);;
    //     }
    // };

    // useEffect(() => {
    //     const storedWaitingList = sessionStorage.getItem('waitingList');
    //     const waitingList = storedWaitingList && JSON.parse(storedWaitingList)
    //     if (waitingList?.appointmentId && waitingList?.patient_id) {
    //         fetchAppointmentDetails(waitingList?.appointmentId, waitingList?.patient_id);
    //     }
    //     else {
    //         fetchAppointmentDetails(null, waitingList?.patient_id);
    //     }
    // }, [location.pathname]);


    return (
        <div className={`${videoCallActiveTab !== "" ? styles.sidebarMain : "h-full mt-6"}`}>
            {
                videoCallActiveTab !== "" &&
                <div className={styles.SLeftSec}>
                    {
                        videoCallActiveTab === '0' ?
                            <VitalMonitoring healthDetails={appointmentDetails?.healthDetails} patientDetails={appointmentDetails?.patientDetails} />
                            :
                            videoCallActiveTab === '1' ?
                                <Documents filesData={appointmentDetails?.files} />
                                :
                                videoCallActiveTab === '2' ?
                                    <GeneratePrescription patientDetails={appointmentDetails?.patientDetails} TalkToDoctorData={TalkToDoctorData} />
                                    : ''
                    }
                </div>
            }
            <div className={styles.SRightSec}>
                {
                    videoCallActiveTab !== "" &&
                    <div title='Click to close' className={`${styles.SIcons} ${videoCallActiveTab === '' ? styles.active : ''}`} onClick={() => dispatch(handleVideoCallActiveTab(''))}>
                        <ToggleIcon color='#000' />
                    </div>
                }
                <div className={`${styles.SIcons} ${videoCallActiveTab === '0' ? styles.active : ''}`} onClick={() => dispatch(handleVideoCallActiveTab('0'))}>
                    <HeartIcon />
                </div>
                <div className={`${styles.SIcons} ${videoCallActiveTab === '1' ? styles.active : ''}`} onClick={() => dispatch(handleVideoCallActiveTab('1'))}>
                    <DocIcon />
                </div>
                <div className={`${styles.SIcons} ${videoCallActiveTab === '2' ? styles.active : ''}`} onClick={() => dispatch(handleVideoCallActiveTab('2'))}>
                    <RxIcon />
                </div>
            </div>
        </div>
    )
}

export default VideoCallSidebar