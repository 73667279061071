import React, { useEffect, useRef, useState, useCallback, ChangeEvent } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../../assets/styles/layout.module.css";
import { InboxIcon, NotificationIcon, ProfileIcon, ToggleIcon } from "../../utils/functions/Icons";
import CustomContainer from "../common/CustomContainer";
import NotificationBox from "../common/NotificationBox";
import { clearAuth, setIsOnline } from "../../utils/store/auth/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { handleToggle } from "../../utils/store/common/CommonSlice";
import { FormControlLabel, Switch } from "@mui/material";
import { RootState } from "../../utils/store";
import { updateActiveStatusApi, updateReadNotificationApi } from "../../utils/api/services";
import { toast } from "react-toastify";
import useSocket from "../../utils/functions/useSocket";

const SidebardHeader: React.FC = () => {
    const [activeNav, setActiveNav] = useState(false);
    const [isAvailable, setIsAvailable] = useState(false)
    const [showNotification, setShowNotification] = useState(false);
    const { LoginData, isOnline } = useSelector((state: RootState) => state.auth)
    const [notificationCount, setNotificationCount] = useState(0)
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { connect, disconnect, sendMessage } = useSocket();

    const headerRef = useRef<HTMLDivElement>(null);
    const notificationButtonRef = useRef<HTMLDivElement>(null);
    const notificationBoxRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        connect();
        return () => {
            disconnect();
        };
    }, [connect, disconnect]);

    const handleAccount = useCallback(() => {
        setActiveNav((prev) => !prev);
    }, []);
    const handleNotification = useCallback(() => {
        setShowNotification((prev) => !prev);
        handleUpdateReadStatus();
    }, []);

    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (
            headerRef.current && !headerRef.current.contains(event.target as Node) &&
            !(document.querySelector('.DoNotCloseNotification')?.contains(event.target as Node))
        ) {
            setActiveNav(false);
        }
        if (
            notificationButtonRef.current && !notificationButtonRef.current.contains(event.target as Node) &&
            notificationBoxRef.current && !notificationBoxRef.current.contains(event.target as Node) &&
            !(document.querySelector('.DoNotCloseNotification')?.contains(event.target as Node))
        ) {
            setShowNotification(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickOutside]);

    const logout = () => {
        dispatch(clearAuth());
        navigate("/login");
    };
    const handleStatus = async (e: ChangeEvent<HTMLInputElement>) => {
        setIsAvailable(e.target.checked)
        dispatch(setIsOnline(e.target.checked))
        const value = e.target.checked === true ? 1 : 0
        try {
            const result = await updateActiveStatusApi({ status: value })
            if (result?.success) {
                toast.success(result?.message)
            } else { toast.error(result?.message) }
        } catch (error: any) {
            toast.error(error?.data?.errors || error?.message);
        }
    }

    const fetchNotification = async (data: {
        role: string;
        referenceId: number;
        filter: string;
    }) => {
        try {
            const result = await sendMessage('getNotifications', data);
            if (result?.success) {
                setNotificationCount(result?.notifications?.totalCount)
            }
        } catch (error: any) {
        }
    }
    useEffect(() => {
        if (LoginData) {
            fetchNotification({ role: LoginData?.role, referenceId: LoginData?.id, filter: 'all' });
        }
    }, [sendMessage]);

    const handleUpdateReadStatus = async () => {
        try {
            const sendData = {
                role: LoginData?.role,
                referenceId: LoginData?.id,
            }
            const result = await updateReadNotificationApi(sendData);
            if (result.success && LoginData) {
                fetchNotification({ role: LoginData?.role, referenceId: LoginData?.id, filter: 'all' });
            }
        } catch (error) {

        }
    }



    const activeInbox = location.pathname.startsWith('/inbox');
    const activeMyaccount = location.pathname.startsWith('/myaccount') || activeNav;

    return (
        <header className={styles.headerSection}>
            <div className={styles.toogleBtnMain} onClick={() => dispatch(handleToggle())}>
                <ToggleIcon />
            </div>
            <CustomContainer nopaddingY={true}>
                <div className={styles.HeaderMain}>
                    <section className={styles.hRightSection}>
                        <div
                            className={`${styles.HRTextIcon}`}
                        >
                            <Switch
                                className="HeaderSwitch"
                                title={isOnline ? "Available for Chat" : "Unavailable for Chat"}
                                value={isOnline}
                                checked={isOnline}
                                onChange={handleStatus}
                            />
                        </div>
                        <div
                            className={`${styles.HRTextIcon} ${showNotification ? styles.ActiveHRTextIcon : ''}`}
                            onClick={handleNotification}
                            ref={notificationButtonRef}
                        >
                            <NotificationIcon />
                            <h3>Notification
                                {
                                    notificationCount !== 0 &&
                                    <span className={styles.notificationCount}>({notificationCount})</span>
                                }
                            </h3>
                            {showNotification && (
                                <div
                                    className={`${styles.HRShowHover} right-0`}
                                    ref={notificationBoxRef}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <NotificationBox />
                                </div>
                            )}
                        </div>
                        <div
                            className={`${styles.HRTextIcon} ${activeMyaccount ? styles.ActiveHRTextIcon : ''}`}
                            onClick={handleAccount}
                            ref={headerRef}
                        >
                            {
                                LoginData?.profilePic ? <img src={LoginData?.profilePic} className={styles.profilePic} alt="user" /> :
                                    <ProfileIcon />
                            }
                            <h3>My account</h3>
                            {activeNav && (
                                <div className={styles.HRShowHover}>
                                    <div className={styles.HRListMain}>
                                        <li onClick={() => navigate('/myaccount/profile')}>My Profile</li>
                                        <li onClick={() => navigate('/myaccount/settings')}>Settings</li>
                                        <li onClick={logout}>Logout</li>
                                    </div>
                                </div>
                            )}
                        </div>
                    </section>
                </div>
            </CustomContainer>
        </header>
    );
};

export default SidebardHeader;
