import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@mui/material';
import React from 'react';
import styles from "../../assets/styles/videocall.module.css";
import { DeleteIcon, EditIcon } from '../../utils/functions/Icons';



interface MedicineTableProps {
    medicines: Medicine[];
    onEdit: (medicine: Medicine) => void;
    onDelete: (medicineId: number) => void;
}

const MedicineTable: React.FC<MedicineTableProps> = ({ medicines, onEdit, onDelete }) => {
    return (
        <TableContainer component={Paper} className='TableContainer'>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className='custumTableHead'>
                    <TableRow className={styles.completeTableRow}>
                        <TableCell className='whitespace-nowrap'>S. No</TableCell>
                        <TableCell className='whitespace-nowrap'>Medicine Name</TableCell>
                        <TableCell className='whitespace-nowrap'>Drug Form</TableCell>
                        <TableCell>Strength</TableCell>
                        <TableCell className='whitespace-nowrap'>Frequency</TableCell>
                        <TableCell className='whitespace-nowrap'>Duration</TableCell>
                        <TableCell>Instructions</TableCell>
                        <TableCell>Options</TableCell>
                    </TableRow>
                </TableHead>
                {
                    medicines?.length > 0 ?
                        <TableBody>
                            {medicines.map((medicine, index) => (
                                <TableRow key={medicine.id} className={`custumTableRow`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">{index + 1}</TableCell>
                                    <TableCell>{(medicine.name?.medicineName && medicine.name?.medicineName !=="Other" )? medicine.name?.medicineName : medicine.otherName}</TableCell>
                                    <TableCell>{medicine.drugForm}</TableCell>
                                    <TableCell>{medicine.strength}</TableCell>
                                    <TableCell>{medicine.frequency}</TableCell>
                                    <TableCell>{medicine.duration}</TableCell>
                                    <TableCell>{medicine.instructions}</TableCell>
                                    <TableCell>
                                        <div className='flex items-center justify-center gap-4'>
                                            <button type='button' onClick={() => onEdit(medicine)}><EditIcon /></button>
                                            <button type='button' onClick={() => onDelete(medicine.id!)}><DeleteIcon /></button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        :
                        <TableBody>
                            <TableRow className={`custumTableRow`}  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="td" scope="row" colSpan={8} >
                                    Nothing to display
                                </TableCell>
                            </TableRow>
                        </TableBody>
                }
            </Table>
        </TableContainer>
    );
};

export default MedicineTable;
