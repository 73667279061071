import { useEffect, useState } from 'react';
import CustomContainer from '../../components/common/CustomContainer';
import styles from "../../assets/styles/appointments.module.css";
import { BackIcon, FileIcon } from '../../utils/functions/Icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppointmentDetaisApi } from '../../utils/api/services';
import { toast } from 'react-toastify';
import Loader from '../../components/common/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../utils/store';
import DocumentViewModal from '../../components/savedDocuments/DocumentViewModal';
import NoDataFound from '../../components/common/NoDataFound';
import UserScore from '../../components/common/UserScore';
import { Tab, Tabs } from '@mui/material';
import { a11yProps, CustomTabPanel } from '../../components/common/CustomTabPanel';
import ChiefComplaints from '../../components/myAppointments/ChiefComplaints';
import PrescribedMedicine from '../../components/myAppointments/PrescribedMedicine';
import { handleViewDocument } from '../../utils/store/common/CommonSlice';
import NoDocuments from '../../components/common/NoDocuments';

const AppointmentDetails = () => {
    const [appointmentDetails, setAppointmentDetails] = useState<AppointmentDetailsResponse | null>(null);
    const [loading, setLoading] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch<AppDispatch>()
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };
    const fetchAppointmentDetails = async (apId: number, docId: number) => {
        try {
            setLoading(true);
            const result = await AppointmentDetaisApi(apId, docId);
            if (result?.success) {
                setLoading(false);
                setAppointmentDetails(result?.response);
            } else {
                setLoading(false);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.data?.errors || error?.message);;
        }
    };

    useEffect(() => {
        if (location?.state?.id && location?.state?.item) {
            fetchAppointmentDetails(location?.state?.id, location?.state?.item?.patientId);
        }
    }, [location.pathname]);

    const handleViewPrescription = (item: AppointmentDetailsResponse['files'][0]) => {
        if (item) {
            dispatch(handleViewDocument({
                fileName: item?.fileName,
                prescriptionURL: item?.fileUrl ? item?.fileUrl : '',
            }))
        }
    }
    if (loading) {
        return <Loader />
    }
    return (
        <CustomContainer>
            <div className='AllPageMain'>
                <div className='HeadAndButton'>
                    <h2 className='flex items-center gap-4 cursor-pointer' onClick={() => navigate(-1)}>
                        <BackIcon />{`${appointmentDetails?.patientDetails?.name} ${`${appointmentDetails?.patientDetails?.uniquePatientId ? `| ${appointmentDetails?.patientDetails?.uniquePatientId}` : ''}`}`}
                    </h2>
                </div>
                <div className={styles.appointmentDetailsPage}>
                    <section className={`${styles.ADetailsLeft}`}>
                        <UserScore patientDetails={appointmentDetails?.patientDetails} />
                    </section>
                    <section className={styles.ADetailsRight}>
                        <Tabs value={tabValue} onChange={handleTabChange} className="custumTabs">
                            <Tab label="Patient Overview" {...a11yProps(0)} />
                            <Tab label="Vital Monitoring" {...a11yProps(1)} />
                            <Tab label="Medicine Prescribed" {...a11yProps(2)} />
                            <Tab label="Uploaded Documents" {...a11yProps(3)} />
                        </Tabs>
                        <CustomTabPanel value={tabValue} index={0}>
                            <ChiefComplaints connectCallLog={appointmentDetails?.connectCallLog} />
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={1}>
                            <UserScore healthDetails={appointmentDetails?.healthDetails} />
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={2}>
                            <PrescribedMedicine medicinePrescribed={appointmentDetails?.medicinePrescribed} />
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={3}>
                            {
                                (appointmentDetails?.files && appointmentDetails?.files?.length > 0) ?
                                    <section className={`col-span-1 ${styles.fileSection}`}>
                                        <div className={styles.fileHead}>
                                            <h3>Files</h3>
                                        </div>
                                        <ul className={styles.fileLists}>
                                            {appointmentDetails?.files?.map((item, index) => {
                                                return (
                                                    <li key={index} className='cursor-pointer' onClick={() => handleViewPrescription(item)}>
                                                        <FileIcon />
                                                        <span>{item?.fileName}</span>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </section>
                                    :
                                    <NoDocuments maxHeight400={true} />
                            }
                        </CustomTabPanel>
                    </section>
                </div>
            </div>
            <DocumentViewModal />
        </CustomContainer >
    );
}

export default AppointmentDetails;
