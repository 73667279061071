import React, { useEffect, useState } from 'react'
import styles from "../../assets/styles/videocall.module.css"
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/store';
import useSocket, { SOCKET_SERVER_URL } from '../../utils/functions/useSocket';
import { Images } from '../../utils/functions/Images';
import { useNavigate } from 'react-router-dom';
import { updateDoctorIdApi, updateStatusByCallIdApi } from '../../utils/api/services';
import { AcceptIcon, DeclineIcon } from '../../utils/functions/Icons';
import { toast } from 'react-toastify';
import { io } from 'socket.io-client';

const IncomingCallList = () => {
    const [incomingCalls, setIncomingCalls] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { connect, listenForEvent, disconnect, sendMessage } = useSocket();
    const { LoginData } = useSelector((state: RootState) => state.auth)
    const navigate = useNavigate();

    useEffect(() => {
        if (incomingCalls.length > 0) {
            setIsModalOpen(true);
        } else {
            setIsModalOpen(false);
        }
    }, [incomingCalls]);
    useEffect(() => {
        connect();
        return () => {
            disconnect();
        };
    }, [connect, disconnect]);

    const fetchIncomingCallSocket = async () => {
        try {
            const result = await sendMessage('incomingCall', { docid: LoginData?.connectDocId, groupId: LoginData?.group_id })
            if (result?.success) {
                const data = result?.data;
                if (data?.waitingList?.length === 0) {
                    if (incomingCalls.length !== 0) {
                        setIncomingCalls([]);
                    }
                    return;
                }
                setIncomingCalls((prevCalls) => {
                    const currentCall = data?.waitingList[0];
                    if (currentCall && currentCall.callHide === 1 && currentCall.status === 'In progress') {
                        return prevCalls.filter(call =>
                            call.waitingList[0]?.call_id !== currentCall.call_id &&
                            call.waitingList[0]?.patient_id !== currentCall.patient_id
                        );
                    }
                    const isDuplicate = prevCalls.some(call =>
                        call.waitingList[0]?.call_id === currentCall?.call_id ||
                        call.waitingList[0]?.patient_id === currentCall?.patient_id
                    );
                    if (!isDuplicate) {
                        const filteredWaitingList = data?.waitingList?.filter((waitingListItem: WaitingListItem) =>
                            waitingListItem.pcp_doctor_id === LoginData?.connectDocId &&
                            waitingListItem.group_id === LoginData?.group_id &&
                            (waitingListItem?.status === 'waiting' || waitingListItem?.status === 'PENDING')
                        );
                        if (filteredWaitingList.length > 0) {
                            return [...prevCalls, data];
                        }
                    }
                    return prevCalls;
                });
            }
        } catch (error) {
        }
    }
    React.useEffect(() => {
        fetchIncomingCallSocket();
    }, [sendMessage]);

    const handleAcceptCall = async (waitingList: WaitingListItem) => {
        if (waitingList) {
            try {
                const sendData = {
                    "callid": waitingList?.call_id,
                    "docid": waitingList?.pcp_doctor_id,
                    "groupid": waitingList?.group_id,
                    "patientid": waitingList?.patient_id
                };
                const result = await updateDoctorIdApi(sendData);
                await updateStatusByCallIdApi({
                    call_id: waitingList?.call_id,
                    status: "In progress",
                    callEnd: 0
                });

                if (result?.success) {
                    setIncomingCalls((prev) => prev.slice(1));
                    // navigate('/callnow', {
                    //     state: {
                    //         waitingList,
                    //     }
                    // });
                    sessionStorage.setItem('waitingList', JSON.stringify(waitingList));
                    window.open('/callnow', '_blank');
                    initializeSocket(waitingList);
                } else {
                    toast.error(result?.message);
                }
            } catch (error: any) {
                toast.error(error?.data?.status_message || error?.message);
            }
        }
    };

    const initializeSocket = (waitingList: WaitingListItem) => {
        try {
            const socket = io(SOCKET_SERVER_URL, {
                transports: ["websocket"],
                secure: true,
                reconnection: true,
                autoConnect: true,
            });

            socket.on("connect", () => {
                socket.emit('incomingCallListUpdate', {
                    docid: waitingList?.pcp_doctor_id,
                    groupid: waitingList?.group_id,
                    patientid: waitingList?.patient_id,
                    callid: waitingList?.call_id
                }, (res: any) => {
                });
                //new socket by mohan
                socket.emit('acceptedCallByDoctor', {
                    userId: waitingList?.patient_id,
                }, (res: any) => {
                });
            });
        } catch (error) {
            console.error("Socket connection error:", error);
        }
    };
    const handleRejectCall = async (waitingList: WaitingListItem) => {
        setIncomingCalls((prev) => prev.slice(1));
        try {
            const sendData = {
                call_id: waitingList?.call_id,
                status: "DELETED",
            }
            const result = await updateStatusByCallIdApi(sendData);
            if (result?.success) {
                setIncomingCalls((prev) => prev.slice(1));
                fetchIncomingCallSocket();
            }
            else {
                toast.error(result?.message)
            }
        } catch (error: any) {
            toast.error(error?.data?.status_message || error?.message);
        }
    };

    return (
        <div className='grid gap-6'>
            {
                incomingCalls && isModalOpen && incomingCalls[0]?.waitingList?.map((item: WaitingListItem, index: number) => {
                    return (
                        <div className={`${styles.CallNotiMain} ${styles.CallNotiBox}`} key={index}>
                            <div className={styles.imgSec}>
                                <img src={item?.profile_image ? item?.profile_image : Images.avtarIcon} alt="User Icon" />
                            </div>
                            <div className={styles.userDetailsSec}>
                                <div className={styles.UNameSec}>
                                    <div className={styles.UName}>
                                        <h3>{`${item?.first_name} ${item?.last_name}`}</h3>
                                        <p>Patient ID: {item?.uniquePatientId}</p>
                                    </div>
                                    <div className={styles.GName}>
                                        <h3>Gender</h3>
                                        <p>{item?.gender}</p>
                                    </div>
                                    <div className={styles.GName}>
                                        <h3>Age</h3>
                                        <p>{item?.age}</p>
                                    </div>
                                </div>
                                <div className={styles.UNameSec}>
                                    <div className={styles.LName}>
                                        <h3>Location</h3>
                                        <p>{`${item?.city}, ${item?.state}`}</p>
                                    </div>
                                    {
                                        item?.approvedByCareNavigator &&
                                        <div className={styles.LName}>
                                            <h3>Care Navigator</h3>
                                            <p>{item?.approvedByCareNavigator}</p>
                                        </div>
                                    }
                                </div>
                                <div className={styles.AdBtnMain}>
                                    <button className='fillButton' onClick={() => handleAcceptCall(item)}> <AcceptIcon /> Accept</button>
                                    <button className='fillButton DeclineBtn' onClick={() => handleRejectCall(item)}><DeclineIcon /> Decline</button>
                                </div>
                            </div>
                        </div>
                    )
                })
            }

        </div>
    )
}

export default IncomingCallList