import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GetCityApi,
  GetMasterApi,
  GetStateApi,
} from "../../api/services";

interface State {
  openModal: boolean;
  master: masterTypes;
  loading: boolean;
  stateData: StateType[];
  cityData: CityType[];
}
export const SpecialitiesReducer = createAsyncThunk<
  State["master"]["specialities"],
  { path: string }
>("profile/SpecialitiesReducer", async (data, thunkAPI) => {
  try {
    const result = await GetMasterApi(data);
    if (result?.success) {
      return result?.data as State["master"]["specialities"];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const ExperiencesReducer = createAsyncThunk<
  State["master"]["experiences"],
  { path: string }
>("profile/ExperiencesReducer", async (data, thunkAPI) => {
  try {
    const result = await GetMasterApi(data);
    if (result?.success) {
      const _Data = result?.data?.map((item: masterTypes['experiences'][0]) => {
        return {
          id: item?.id,
          yearsOfExperience: item?.yearsOfExperience?.toString()
        }
      })
      return _Data as State["master"]["experiences"];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const LanguagesReducer = createAsyncThunk<
  State["master"]["languages"],
  { path: string }
>("profile/LanguagesReducer", async (data, thunkAPI) => {
  try {
    const result = await GetMasterApi(data);
    if (result?.success) {
      return result?.data as State["master"]["languages"];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const OrganizationsReducer = createAsyncThunk<
  State["master"]["organizations"],
  { path: string }
>("profile/OrganizationsReducer", async (data, thunkAPI) => {
  try {
    const result = await GetMasterApi(data);
    if (result?.success) {
      return result?.data as State["master"]["organizations"];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const EducationsReducer = createAsyncThunk<
  State["master"]["educationData"],
  { path: string }
>("profile/EducationsReducer", async (data, thunkAPI) => {
  try {
    const result = await GetMasterApi(data);
    if (result?.success) {
      return result?.data as State["master"]["educationData"];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const getStateReducer = createAsyncThunk<State["stateData"]>(
  "profile/getStateReducer",
  async (data, thunkAPI) => {
    try {
      const result = await GetStateApi();
      if (result?.success) {
        return result?.states as State["stateData"];
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getCiyReducer = createAsyncThunk<
  State["cityData"],
  { state_id: number }
>("profile/getCiyReducer", async (data, thunkAPI) => {
  try {
    const result = await GetCityApi(data);
    if (result?.success) {
      return result?.cities as State["cityData"];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState: State = {
  openModal: false,
  master: {
    educationData: [],
    specialities: [],
    experiences: [],
    languages: [],
    organizations: []
  },
  loading: false,
  stateData: [],
  cityData: [],
};

const ProfileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    updateGenerateAbha: (state, action: PayloadAction) => {},
    handleOpenModal: (state) => {
      state.openModal = true;
    },
    handleCloseModal: (state) => {
      state.openModal = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      EducationsReducer.fulfilled,
      (
        state,
        action: PayloadAction<State["master"]["educationData"] | undefined>
      ) => {
        state.master.educationData = action.payload ?? [];
      }
    );
    builder.addCase(
      ExperiencesReducer.fulfilled,
      (
        state,
        action: PayloadAction<State["master"]["experiences"] | undefined>
      ) => {
        state.master.experiences = action.payload ?? [];
      }
    );
    builder.addCase(
      SpecialitiesReducer.fulfilled,
      (
        state,
        action: PayloadAction<State["master"]["specialities"] | undefined>
      ) => {
        state.master.specialities = action.payload ?? [];
      }
    );
    builder.addCase(
      LanguagesReducer.fulfilled,
      (
        state,
        action: PayloadAction<State["master"]["languages"] | undefined>
      ) => {
        state.master.languages = action.payload ?? [];
      }
    );
    builder.addCase(
      OrganizationsReducer.fulfilled,
      (
        state,
        action: PayloadAction<State["master"]["organizations"] | undefined>
      ) => {
        state.master.organizations = action.payload ?? [];
      }
    );
    builder.addCase(
      getStateReducer.pending,
      (state, action: PayloadAction<State["stateData"] | undefined>) => {
        state.stateData = [];
      }
    );
    builder.addCase(
      getStateReducer.fulfilled,
      (state, action: PayloadAction<State["stateData"] | undefined>) => {
        state.stateData = action.payload ?? [];
      }
    );
    builder.addCase(getStateReducer.rejected, (state) => {
      state.stateData = [];
    });
    builder.addCase(getCiyReducer.pending, (state) => {
      state.cityData = [];
    });
    builder.addCase(
      getCiyReducer.fulfilled,
      (state, action: PayloadAction<State["cityData"] | undefined>) => {
        state.cityData = action.payload ?? [];
      }
    );
    builder.addCase(getCiyReducer.rejected, (state) => {
      state.cityData = [];
    });
  },
});

export const {
  handleOpenModal,
  handleCloseModal,
} = ProfileSlice.actions;

export default ProfileSlice;
