import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Login from './pages/Login'
import ScrollToTop from './components/common/ScrollToTop'
import Layout from './components/layout'
import ForgotPassword from './pages/ForgotPassword'
import LoginOtp from './pages/LoginOtp'
import ChangePassword from './pages/ChangePassword'
import Dashboard from './pages/AuthPages/Dashboard'
import Profile from './pages/AuthPages/Profile'
import MyAppointment from './pages/AuthPages/MyAppointment'
import SavedDocument from './pages/AuthPages/SavedDocument'
import SavedDocumentView from './pages/AuthPages/SavedDocumentView'
import BookAppointmentThanks from './pages/AuthPages/BookAppointmentThanks'
import Help from './pages/AuthPages/Help'
import AppointmentDetails from './pages/AuthPages/AppointmentDetails'
import ProtectedRoute from './components/common/ProtectedRoute'
import { useSelector } from 'react-redux'
import { RootState } from './utils/store'
import CallNow from './pages/AuthPages/CallNow'
import Chat from './pages/AuthPages/Chat'
import Setting from './pages/AuthPages/Setting'
import Availability from './pages/AuthPages/Availability'
import MyPatients from './pages/AuthPages/MyPatients'
import useSocket from './utils/functions/useSocket'
import GlobalCallNotification from './components/common/GlobalCallNotification'
import AuthRoute from './components/common/AuthRoute'

const App = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  const [incomingCalls, setIncomingCalls] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { connect, listenForEvent, disconnect, sendMessage } = useSocket();
  const { LoginData } = useSelector((state: RootState) => state.auth)
  useEffect(() => {
    connect();
    return () => {
      disconnect();
    };
  }, [connect, disconnect]);

  useEffect(() => {
    if (incomingCalls.length > 0) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [incomingCalls]);
  React.useEffect(() => {
    listenForEvent(`incomingCallListFront`, (data: any) => {
      setIncomingCalls((prevCalls) => {
        const currentCall = data?.waitingList[0];
        if (currentCall && currentCall.callHide === 1 && currentCall.status === 'In progress') {
          return prevCalls.filter(call =>
            call.waitingList[0]?.call_id !== currentCall.call_id &&
            call.waitingList[0]?.patient_id !== currentCall.patient_id
          );
        }
        const isDuplicate = prevCalls.some(call =>
          call.waitingList[0]?.call_id === currentCall?.call_id ||
          call.waitingList[0]?.patient_id === currentCall?.patient_id
        );
        if (!isDuplicate) {
          const filteredWaitingList = data?.waitingList?.filter((waitingListItem: WaitingListItem) =>
            waitingListItem.pcp_doctor_id === LoginData?.connectDocId &&
            waitingListItem.group_id === LoginData?.group_id &&
            (waitingListItem?.status === 'waiting' || waitingListItem?.status === 'PENDING')
          );
          if (filteredWaitingList.length > 0) {
            return [...prevCalls, data];
          }
        }
        return prevCalls;
      });
    });
  }, [listenForEvent, LoginData]);

  return (
    <>
      {
        token && isModalOpen &&
        <GlobalCallNotification
          incomingCalls={incomingCalls}
          setIncomingCalls={setIncomingCalls}
        />
      }
      <ScrollToTop />
      <Routes>
        <Route path='/login' element={
          <AuthRoute>
            <Login />
          </AuthRoute>
        } />
        <Route path='/forgot-password' element={
          <AuthRoute>
            <ForgotPassword />
          </AuthRoute>
        } />
        <Route path='/otp' element={
          <AuthRoute>
            <LoginOtp />
          </AuthRoute>
        } />
        <Route path='/change-password' element={
          <AuthRoute>
            <ChangePassword />
          </AuthRoute>
        } />
        {
          token && <Route path='CallNow' element={<CallNow />} />
        }
        <Route path="/*"
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }>
          <Route path='myaccount/profile' element={<Profile />} />
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='appointments' element={<MyAppointment />} />
          <Route path='MyAppointments/book/thanks' element={<BookAppointmentThanks />} />
          <Route path='SavedDocuments' element={<SavedDocument />} />
          <Route path='SavedDocuments/view' element={<SavedDocumentView />} />
          <Route path='help' element={<Help />} />
          <Route path='availability' element={<Availability />} />
          <Route path='MyAppointments/details' element={<AppointmentDetails />} />
          <Route path='myaccount/settings' element={<Setting />} />
          {/* <Route path='CallNow' element={<CallNow />} /> */}
          <Route path='patient' element={<MyPatients />} />
          <Route path='inbox' element={<Chat />} />
          <Route path='*' element={<Dashboard />} />
          <Route
            path="/*"
            element={<Navigate to={"/dashboard"} replace />}
          />
        </Route>
        <Route
          path="/"
          element={<Navigate to={!token ? "/login" : '/patient'} replace />}
        />
      </Routes>
    </>
  )
}

export default App