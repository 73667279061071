import React, { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

interface PropTypes {
  appointmentStats: DoctorDashboardData['appointmentStats'] | undefined;
}

const AppointmentChart: FC<PropTypes> = ({ appointmentStats }) => {
  const appointmentWeeks = appointmentStats?.map(stat => `Week ${stat.week}`);
  const upcomingCounts = appointmentStats?.map(stat => parseInt(stat.upcomingCount));
  const completedCounts = appointmentStats?.map(stat => parseInt(stat.completedCount));
  const cancelledCounts = appointmentStats?.map(stat => parseInt(stat.cancelledCount));

  const appointmentsData = {
    labels: appointmentWeeks,
    datasets: [
      {
        label: 'Upcoming',
        data: upcomingCounts,
        backgroundColor: '#9DCAFF',
      },
      {
        label: 'Completed',
        data: completedCounts,
        backgroundColor: '#0562CC',
      },
      {
        label: 'Cancelled',
        data: cancelledCounts,
        backgroundColor: '#E5E7EB',
      },
    ],
  };

  const appointmentsOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top' as const,
      },
      title: {
        display: true,
        text: '',
      },
    },
  };

  return (
    <div>
      <Bar data={appointmentsData} options={appointmentsOptions} />
    </div>
  );
};

export default AppointmentChart;