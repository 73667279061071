import React, { ChangeEvent, FC } from 'react'
import { CrossIcon, RemoveCircleIcon } from '../../utils/functions/Icons';

interface Props {
    title: string,
    FileIcon?: FC<React.SVGProps<SVGSVGElement>>
    colorBorder?: boolean,
    handleFileChange?: (name: string, file: File | null, index?:number) => void;
    name?: string,
    onlyImg?: boolean,
    disable?: boolean,
    fillBg?: boolean,
    id: string;
    previewUrl?: string;
    index?: number;
    fullWidth?: boolean;
    handleRemoveFile?: () => void;
}
const InputFile: FC<Props> = ({
    title,
    FileIcon,
    colorBorder,
    handleFileChange,
    name = '',
    onlyImg,
    disable,
    fillBg,
    id = '',
    previewUrl,
    fullWidth,
    handleRemoveFile,
    index
}) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const file = e.target.files?.[0] ?? null;
        if (handleFileChange) {
            if(index !== undefined && index !==null){
                handleFileChange(name, file, index);
            }else{
                handleFileChange(name, file);
            }
        }
    };
    return (
        <div className="customFileInputMain">
            <input
                type="file"
                name={name}
                id={id}
                accept={onlyImg ? '.jpeg,.jpg,.png' : '.jpeg,.jpg,.png,.txt,.xlsx,.xls,.csv,.pdf,.doc,.docx'}
                onChange={handleChange}
                multiple={false}
                className="customFileInput"
                style={{ display: 'none' }}
            />
            <label className={`cursor-pointer customFileInputLabel ${colorBorder ? 'colorBorder' : ''} ${fillBg ? 'fillBackground' : ''} ${fullWidth ? 'fullWidthFileUpload' : ''}`} htmlFor={id}>
                {FileIcon && <span><FileIcon /></span>}  {title}
            </label>
            {previewUrl && (
                <div className="filePreviewImg">
                    <img src={previewUrl} alt="Selected file preview" className="previewImage cursor-pointer" />
                    <span onClick={handleRemoveFile} className='cursor-pointer' title='remove file'> <RemoveCircleIcon /></span>
                </div>
            )}
        </div>
    )
}

export default InputFile