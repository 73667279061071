import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { FC } from 'react'
import NoDataFound from '../common/NoDataFound'
import styles from "../../assets/styles/appointments.module.css";

interface PropTypes {
    medicinePrescribed: AppointmentDetailsResponse['medicinePrescribed'] | undefined
}
const PrescribedMedicine: FC<PropTypes> = ({ medicinePrescribed }) => {
    return (
        <div>
            <TableContainer component={Paper} className='TableContainer'>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className='custumTableHead'>
                        <TableRow className={styles.completeTableRow}>
                            <TableCell className='whitespace-nowrap'>Medicine Name</TableCell>
                            <TableCell align="right" className='whitespace-nowrap'>Drug Form</TableCell>
                            <TableCell align="right" className='whitespace-nowrap'>Strength</TableCell>
                            <TableCell align="right" className='whitespace-nowrap'>Frequency</TableCell>
                            <TableCell align="right" className='whitespace-nowrap'>Duration</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    {
                        medicinePrescribed && medicinePrescribed?.length > 0 ?
                            <TableBody>
                                {
                                    medicinePrescribed?.map((item, index) => {
                                        return (
                                            <TableRow
                                                key={index}
                                                className='custumTableRow'
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {item?.medicineName}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {item?.drugForm}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {item?.strength}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {item?.frequency}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {item?.duration}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }

                            </TableBody>
                            :
                            <TableBody>
                                <TableRow
                                    className='custumTableRow'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row" colSpan={5}>
                                        <NoDataFound />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                    }
                </Table>
            </TableContainer>
        </div>
    )
}

export default PrescribedMedicine