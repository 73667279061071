import React, { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import { monthsData } from '../../utils/functions/Data';
interface PropTypes {
  patientStats: DoctorDashboardData['patientStats'] | undefined;
}

const PatientChart: FC<PropTypes> = ({ patientStats }) => {
  const totalVisitsArray = Array(12).fill(0);

  patientStats?.forEach(stat => {
    totalVisitsArray[stat.month - 1] = stat.totalVisits;
  });

  const visitsData = {
    labels: monthsData?.map((item) => item?.name),
    datasets: [
      {
        label: 'Total Visits',
        data: totalVisitsArray,
        backgroundColor: '#0562CC',
      },
    ],
  };

  const visitsOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: '',
      },
    },
  };

  return (
    <div>
      <Bar data={visitsData} options={visitsOptions} />
    </div>
  );
};

export default PatientChart;