import { FC } from "react"
import noDocImg from "../../assets/images/common/nodoc.png"
interface PropTypes {
    maxHeight400?: boolean;
}
const NoDocuments: FC<PropTypes> = ({ maxHeight400 }) => {
    return (
        <div className='flex items-center justify-center flex-col w-full'>
            <img src={noDocImg} alt="No Appointment" style={{ maxHeight: maxHeight400 ? '300px' : '' }} />
            <h3>No documents available here</h3>
        </div>
    )
}

export default NoDocuments