import styles from "../../assets/styles/layout.module.css"
import logo from "../../assets/images/layout/sidebarlogo.png"
import plusLogo from "../../assets/images/layout/pluslogo.png"
import SidebarData from "./SidebarData"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../utils/store"
import React from "react"
import { handleToggle } from "../../utils/store/common/CommonSlice"
const Sidebar = () => {
    const { sidebarToggle } = useSelector((state: RootState) => state.common)
    const { isCareNavigator } = useSelector((state: RootState) => state.auth)
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const handleNavigate = (item: SidebarDataType) => {
        navigate(item.path)
        if (window.innerWidth <= 768) {
            dispatch(handleToggle(true));
        }
    }

    return (
        <div className={`${styles.sidebarMain} ${sidebarToggle ? styles.toggle : ''}`}>
            <div className={`${sidebarToggle ? styles.sidebarLogoToggle : styles.sidebarLogo}`}>
                <img src={sidebarToggle ? plusLogo : logo} alt="logo" />
            </div>
            <ul className={styles.sidebarItemsMain}>
                {
                    SidebarData?.map((item, index) => {
                        const isActive = location.pathname.startsWith(item.path);
                        return (
                            <React.Fragment key={index}>
                                {
                                    item?.title === 'Talk to Doctor' && isCareNavigator ?
                                        <li key={index} className={isActive ? styles.ActiveLink : ''}>
                                            <div className={styles.sidebarIcon}>
                                                <item.icon />
                                            </div>
                                            <Link to={"#"}>{item?.title}</Link>
                                        </li>
                                        :
                                        <li key={index} className={isActive ? styles.ActiveLink : ''} onClick={() => handleNavigate(item)}>
                                            <div className={styles.sidebarIcon}>
                                                <item.icon />
                                            </div>
                                            <Link to={item.path}>{item?.title}</Link>
                                        </li>
                                }
                            </React.Fragment>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default Sidebar