import React, { FC } from 'react'
import styles from "../../assets/styles/teams.module.css"
import { Images } from '../../utils/functions/Images'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../utils/store'
import { handleViewDocument } from '../../utils/store/common/CommonSlice'
import DocumentViewModal from '../savedDocuments/DocumentViewModal'
import NoDocuments from '../common/NoDocuments'
import { FileIcon } from '../../utils/functions/Icons'
interface PropsType {
  filesData: AppointmentDetailsResponse['files'] | undefined
}
const Documents: FC<PropsType> = ({ filesData }) => {
  const dispatch = useDispatch<AppDispatch>();
  const handleViewPrescription = (item?: AppointmentDetailsResponse['files'][0] | undefined) => {
    dispatch(handleViewDocument({
      prescriptionURL: item?.fileUrl ? item?.fileUrl : '',
      fileName: item?.fileName
    }))
  }

  return (
    <div>
      <div className={styles.secHeading}>
        <h3>Documents uploaded </h3>
      </div>
      {
        filesData && filesData?.length > 0 ?
          <ul className={styles.fileLists}>
            {filesData?.map((item, index) => {
              return (
                <li key={index} className='cursor-pointer' onClick={() => handleViewPrescription(item)}>
                  <FileIcon />
                  <span>{item?.fileName}</span>
                </li>
              )
            })}
          </ul>
          :
          <NoDocuments maxHeight400={true} />
      }

      <DocumentViewModal />
    </div>
  )
}

export default Documents