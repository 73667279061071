import React, { FormEvent, useEffect, useRef, useState } from 'react';
import styles from "../../assets/styles/videocall.module.css";
import { FormGroup, FormLabel, InputAdornment, MenuItem, Select, SelectChangeEvent, TextareaAutosize, TextField } from '@mui/material';
import { handleKeyPress } from '../../utils/functions/common';
import MedicineTable from './MedicineTable';
import MedicineDetails from './MedicineDetails';
import BtnLoader from '../common/BtnLoader';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/store';
import { savePrescriptionApi } from '../../utils/api/services';
interface PropsType {
  TalkToDoctorData: WaitingListItem | null;
  patientDetails: AppointmentDetailsResponse['patientDetails'] | undefined
}
const GeneratePrescription: React.FC<PropsType> = ({ TalkToDoctorData, patientDetails }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [medicines, setMedicines] = useState<Medicine[]>([]);
  const [selectedMedicine, setSelectedMedicine] = useState<Medicine | null>(null);
  const [formState, setFormState] = useState({
    chiefComplaints: '',
    diagnosis: '',
    previousHistory: '',
    labFindings: '',
    suggestedInvestigations: '',
    specialInstructions: '',
  })

  const { LoginData } = useSelector((state: RootState) => state.auth);

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value })
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value })
  }
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedMedicine(null);
  };

  const handleSaveMedicine = (medicine: Medicine) => {
    if (selectedMedicine) {
      setMedicines((prev) =>
        prev.map((med) => (med.id === selectedMedicine.id ? { ...medicine, id: med.id } : med))
      );
    } else {
      setMedicines([...medicines, { ...medicine, id: Date.now() }]);
    }
    setOpenModal(false);
  };

  const handleEditMedicine = (medicine: Medicine) => {
    setSelectedMedicine(medicine);
    setOpenModal(true);
  };

  const handleDeleteMedicine = (medicineId: number) => {
    setMedicines(medicines.filter((medicine) => medicine.id !== medicineId));
  };

  const handleSavePres = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const _medicineData = medicines?.map((item) => {
        return {
          medicineId: item?.name?.id ? item?.name?.id : 0,
          medicineName: item?.name?.medicineName !== 'Other' ? item?.name?.medicineName : item?.otherName,
          frequency: item?.frequency,
          duration: item?.duration,
          drugForm: item?.drugForm ? item?.drugForm : '',
          strength: item?.strength ? item?.strength : '',
          instructions: item?.instructions ? item?.instructions : ''
        }
      })
      const sendData = {
        "patientPrescriptionDetails": {
          "previousHistory": formState?.previousHistory,
          "diagnosis": formState?.diagnosis,
          "labFindings": formState?.labFindings,
          "suggestedInvestigations": formState?.suggestedInvestigations,
          "specialInstructions": formState?.specialInstructions,
          "chiefComplaints": formState?.chiefComplaints,
          "patientId": patientDetails?.patientId,
          "appointmentId": TalkToDoctorData?.appointmentId ? TalkToDoctorData?.appointmentId : 0,
          "callId": TalkToDoctorData?.call_id,
          "signature": LoginData?.signature,
          "patientName": patientDetails?.name,
          "patientEmail": patientDetails?.email,
          "phone": patientDetails?.phone,
          "age": patientDetails?.age,
          "height": patientDetails?.height,
          "weight": patientDetails?.weight,
          "gender": patientDetails?.gender,
          "location": patientDetails?.location,
          "doctorEmail": LoginData?.email,
        },
        medicineDetails: _medicineData
      }
      setBtnLoading(true)
      const result = await savePrescriptionApi(sendData)
      if (result?.success) {
        setBtnLoading(false)
        toast.success(result?.message)
        setFormState({
          chiefComplaints: '',
          diagnosis: '',
          previousHistory: '',
          labFindings: '',
          suggestedInvestigations: '',
          specialInstructions: '',
        })
        setMedicines([]);
      }
      else {
        setBtnLoading(false)
        toast.error(result?.message)
      }
    } catch (error: any) {
      setBtnLoading(false)
      toast.error(error?.message)
    }
  }
  // api part end
  return (
    <div className={styles.presMain}>
      <form className={styles.form} onSubmit={handleSavePres}>
        <section className={styles.DiagnosisSection}>
          <div className='grid grid-cols-1 gap-6'>
            <FormGroup className="customFormGroup">
              <FormLabel className="customFormLabel">Chief Complaints</FormLabel>
              <TextareaAutosize
                minRows={2}
                className={`custumTextField profileInput`}
                name='chiefComplaints'
                value={formState.chiefComplaints}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup className="customFormGroup">
              <FormLabel className="customFormLabel">Diagnosis Or Provisional Diagnosis</FormLabel>
              <TextareaAutosize
                minRows={2}
                className={`custumTextField profileInput`}
                name='diagnosis'
                value={formState?.diagnosis}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup className="customFormGroup">
              <FormLabel className="customFormLabel">Relevant Points From History</FormLabel>
              <TextareaAutosize
                minRows={2}
                className={`custumTextField profileInput`}
                name='previousHistory'
                value={formState?.previousHistory}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup className="customFormGroup">
              <FormLabel className="customFormLabel">Examinations/Lab Findings</FormLabel>
              <TextareaAutosize
                minRows={2}
                className={`custumTextField profileInput`}
                name='labFindings'
                value={formState?.labFindings}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup className="customFormGroup">
              <FormLabel className="customFormLabel">Suggested Investigations</FormLabel>
              <TextareaAutosize
                minRows={2}
                className={`custumTextField profileInput`}
                name='suggestedInvestigations'
                value={formState?.suggestedInvestigations}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup className="customFormGroup">
              <FormLabel className="customFormLabel">Special Instructions</FormLabel>
              <TextareaAutosize
                minRows={2}
                className={`custumTextField profileInput`}
                name='specialInstructions'
                value={formState?.specialInstructions}
                onChange={handleChange}
              />
            </FormGroup>
          </div>
        </section>
        <section>
          <div className={styles.heading}>
            <h3>Medicine Details</h3>
          </div>
          <div className={styles.addmedicationBtn}>
            <button type='button' className='fillButton' onClick={() => setOpenModal(true)}>+ Add Medication</button>
          </div>
          <div className='mt-6'>
            <MedicineTable medicines={medicines} onEdit={handleEditMedicine} onDelete={handleDeleteMedicine} />
          </div>
        </section>
        <div className='mt-10'>
          <button type='submit' disabled={btnLoading} className='fillButton flexRowImp'> {btnLoading && <BtnLoader />} Generate Prescription</button>
        </div>
      </form>
      <MedicineDetails
        openModal={openModal}
        btnLoading={btnLoading}
        handleCloseModal={handleCloseModal}
        saveMedicine={handleSaveMedicine}
        selectedMedicine={selectedMedicine}
      />
    </div>
  );
};

export default GeneratePrescription;
