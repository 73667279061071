import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styles from "../../assets/styles/appointments.module.css"
import { FormGroup, InputAdornment, TextField } from '@mui/material';
import { SearchIcon, ShowPasswordIcon } from '../../utils/functions/Icons';
import { useEffect, useState } from 'react';
import Loader from '../common/Loader';
import NoDataFound from '../common/NoDataFound';
import { Images } from '../../utils/functions/Images';
import { getMyPatientsApi } from '../../utils/api/services';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const MyPatientTable = () => {
    const [searchValue, setSearchValue] = useState('')
    const [loading, setLoading] = useState(false)
    const [patientData, setPatientData] = useState<DoctorAppointmentTypes[] | []>([]);
    const navigate = useNavigate();

    const fetchMyPatient = async (data: { search: string }) => {
        try {
            setLoading(true)
            const result = await getMyPatientsApi(data);
            if (result?.success) {
                setLoading(false)
                setPatientData(result?.patients)
            } else { setLoading(false) }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    useEffect(() => {
        if (searchValue) {
            const cleanFunction = setTimeout(() => {
                fetchMyPatient({ search: searchValue || '' })
            }, 1000);
            return () => clearTimeout(cleanFunction);
        } else {
            fetchMyPatient({ search: searchValue || '' })
        }
    }, [searchValue]);

    const handleViewDetails = (item: DoctorAppointmentTypes) => {
        navigate('/MyAppointments/details', {
            state: {
                id: item?.appointmentId,
                item: item
            }
        })
    }

    if (loading) {
        return <Loader />
    }
    
    return (
        <div>
            <div className={'md:w-1/2 w-full mb-6'}>
                <FormGroup className="customFormGroup">
                    <TextField
                        className={`custumTextField ${styles.InputStyle}`}
                        id="searchValue"
                        name="searchValue"
                        onChange={(e) => setSearchValue(e.target.value)}
                        value={searchValue}
                        placeholder='Search...'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormGroup>
            </div>
            {
                loading ? <Loader />
                    :
                    <TableContainer component={Paper} className='TableContainer'>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead className='custumTableHead'>
                                <TableRow className={styles.completeTableRow}>
                                    <TableCell className='whitespace-nowrap'>Patient Name</TableCell>
                                    <TableCell align="right" className='whitespace-nowrap'>Appt. Date & Time</TableCell>
                                    <TableCell align="right" className='whitespace-nowrap'>Care Navigator</TableCell>
                                    <TableCell align="right" className='whitespace-nowrap'>Contact No.</TableCell>
                                    <TableCell align="right" className='whitespace-nowrap'>Status</TableCell>
                                    <TableCell align="right" className='whitespace-nowrap'></TableCell>
                                </TableRow>
                            </TableHead>
                            {
                                patientData?.length > 0 ?
                                    <TableBody>
                                        {
                                            patientData?.map((item, index) => {
                                                return (
                                                    <TableRow
                                                        key={index}
                                                        className='custumTableRow'
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            <div className={styles.doctorSec}>
                                                                <img src={item?.profilePic ? item?.profilePic : Images.avtarIcon} alt="user icon" />
                                                                {item?.patientName}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item?.dateTime}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item?.careNavigatorName ? item?.careNavigatorName : 'N/A'}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item?.phone}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <button className={`${styles.StatusBtn} ${item?.appointmentStatus === 'Upcoming' ? styles.StatusBtnPending : item?.appointmentStatus === "Missed" ? styles.MissedApp : ''}`}>{item?.appointmentStatus}</button>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <button
                                                                className={`fillButton ${styles.downloadPresBtn}`}
                                                                onClick={() => handleViewDetails(item)}
                                                            >
                                                                <ShowPasswordIcon color='#fff' /> View</button>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                    :
                                    <TableBody>
                                        <TableRow
                                            className='custumTableRow'
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" colSpan={5}>
                                                <NoDataFound />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                            }
                        </Table>
                    </TableContainer>
            }
        </div>
    );
}
export default MyPatientTable