import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styles from "../../assets/styles/appointments.module.css"
import { FormGroup, InputAdornment, TextField } from '@mui/material';
import {  SearchIcon, ShowPasswordIcon } from '../../utils/functions/Icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../utils/store';
import { fetchCompletedAppReducer } from '../../utils/store/appointment/AppointmentSlice';
import Loader from '../common/Loader';
import NoDataFound from '../common/NoDataFound';
import { useNavigate } from 'react-router-dom';
import DocumentViewModal from '../savedDocuments/DocumentViewModal';
import { Images } from '../../utils/functions/Images';
export default function Completed() {
    const [searchValue, setSearchValue] = useState('')
    const { loading, CompletedAppointment, } = useSelector((state: RootState) => state.appointment)
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate();
    useEffect(() => {
        if (searchValue) {
            const cleanFunction = setTimeout(() => {
                dispatch(fetchCompletedAppReducer({ search: searchValue || '', id: 2 }));
            }, 1000);
            return () => clearTimeout(cleanFunction);
        } else {
            dispatch(fetchCompletedAppReducer({ search: searchValue || '', id: 2 }));
        }
    }, [searchValue]);

    const handleViewDetails = (item: DoctorAppointmentTypes) => {
        navigate('/MyAppointments/details', {
            state: {
                id: item?.appointmentId,
                item: item
            }
        })
    }
    return (
        <div>
            <div className={'md:w-1/2 w-full mb-6'}>
                <FormGroup className="customFormGroup">
                    <TextField
                        className={`custumTextField ${styles.InputStyle}`}
                        id="searchValue"
                        name="searchValue"
                        onChange={(e) => setSearchValue(e.target.value)}
                        value={searchValue}
                        placeholder='Search...'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormGroup>
            </div>
            {
                loading ? <Loader />
                    :
                    <TableContainer component={Paper} className='TableContainer'>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead className='custumTableHead'>
                                <TableRow className={styles.completeTableRow}>
                                    <TableCell className='whitespace-nowrap'>Patient Name</TableCell>
                                    <TableCell align="right" className='whitespace-nowrap'>Appt. Date & Time</TableCell>
                                    <TableCell align="right" className='whitespace-nowrap'>Care Navigator</TableCell>
                                    <TableCell align="right" className='whitespace-nowrap'>Contact No.</TableCell>
                                    <TableCell align="right" className='whitespace-nowrap'>Call duration</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            {
                                CompletedAppointment?.length > 0 ?
                                    <TableBody>
                                        {
                                            CompletedAppointment?.map((item, index) => {
                                                return (
                                                    <TableRow
                                                        key={index}
                                                        className='custumTableRow'
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            <div className={styles.doctorSec}>
                                                                <img src={item?.profilePic ? item?.profilePic : Images.avtarIcon} alt="user icon" />
                                                                {item?.patientName}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item?.dateTime}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item?.careNavigatorName ? item?.careNavigatorName : 'N/A'}

                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item?.phone}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item?.callDuration}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <button
                                                                className={`fillButton ${styles.downloadPresBtn}`}
                                                                onClick={() => handleViewDetails(item)}
                                                            >
                                                                <ShowPasswordIcon color='#fff' /> View</button>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }

                                    </TableBody>
                                    :
                                    <TableBody>
                                        <TableRow
                                            className='custumTableRow'
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" colSpan={6}>
                                                <NoDataFound />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                            }
                        </Table>
                    </TableContainer>
            }
            <DocumentViewModal />
        </div>
    );
}