import CustomContainer from "../../components/common/CustomContainer"
import styles from "../../assets/styles/help.module.css"
import { Accordion, AccordionDetails, AccordionSummary, FormGroup, FormLabel, InputAdornment, TextField, TextareaAutosize, Typography } from "@mui/material"
import { ExpandMoreIcon, ExpandedMoreIcon, SearchIcon } from "../../utils/functions/Icons"
import { FormEvent, useEffect, useState } from "react"
import thanksImg from "../../assets/images/common/contactthanks.png"
import { helpFaqApi, helpSendEmailApi } from "../../utils/api/services"
import { toast } from "react-toastify"
import Loader from "../../components/common/Loader"
import NoDataFound from "../../components/common/NoDataFound"
import BtnLoader from "../../components/common/BtnLoader"
type faqTypes = {
    title: string,
    description: string
}
const Help = () => {
    const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({});
    const [isSubmited, setIsSubmited] = useState(false)
    const [loading, setLoading] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false);
    const [message, setMessage] = useState('')
    const [faqData, setFaqData] = useState<faqTypes[]>([]);
    const [searchValue, setSearchValue] = useState('')
    const handleExpansion = (index: number) => {
        setExpanded((prevExpanded) => ({
            [index]: !prevExpanded[index]
        }));
    };

    const fetchFaqApi = async (data?: { q: string }) => {
        try {
            setLoading(true)
            const result = await helpFaqApi(data);
            if (result?.success) {
                setLoading(false)
                setFaqData(result?.faqData)
            } else { setLoading(false) }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    useEffect(() => {
        if (searchValue) {
            const cleanFunction = setTimeout(() => {
                fetchFaqApi({
                    q: searchValue
                });
            }, 1000);
            return () => clearTimeout(cleanFunction)
        }
        else {
            fetchFaqApi();
        }

    }, [searchValue])

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            setBtnLoading(true)
            const result = await helpSendEmailApi({
                message
            })
            if (result?.success) {
                setBtnLoading(false)
                toast.success(result?.message)
                setIsSubmited(true)
                setMessage('')
            } else {
                setBtnLoading(false)
                toast.error(result?.message)
            }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    return (
        <CustomContainer>
            <div className='AllPageMain'>
                <div className='HeadAndButton'>
                    <h2>Help</h2>
                </div>
                <div className='md:w-1/2 w-full mt-6'>
                    <FormGroup className="customFormGroup">
                        <TextField
                            className={`custumTextField TextFieldAll ${styles.InputStyle}`}
                            id="searchValue"
                            name="searchValue"
                            placeholder='Search by Keywords'
                            onChange={(e) => setSearchValue(e.target.value)}
                            value={searchValue}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormGroup>
                </div>
                <div className={styles.helpMain}>
                    <div className="grid grid-cols-1 xl:grid-cols-3 xl:gap-6 gap-y-6">
                        <div className={`xl:col-span-2 ${styles.faqSec}`}>
                            {
                                loading ? <Loader />
                                    :
                                    <>
                                        {
                                            faqData?.length > 0 ?
                                                <>
                                                    {faqData?.map((item, index) => {
                                                        const isExpanded = !!expanded[index];
                                                        return (
                                                            <Accordion
                                                                expanded={isExpanded}
                                                                onChange={() => handleExpansion(index)}
                                                                className="CustomAccordion"
                                                                key={index}
                                                            >
                                                                <AccordionSummary
                                                                    expandIcon={
                                                                        isExpanded ?
                                                                            <ExpandedMoreIcon />
                                                                            : <ExpandMoreIcon />
                                                                    }
                                                                    aria-controls="helpContent"
                                                                    id={`helpContent-${index}`}
                                                                    className="AccordionSummary"
                                                                >
                                                                    <Typography className="AccordionHeader">{item?.title}</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails className="accordionDetails">
                                                                    <Typography>
                                                                        {item?.description}
                                                                    </Typography>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        )
                                                    })}
                                                </>
                                                :
                                                <NoDataFound />
                                        }
                                    </>
                            }

                        </div>
                        <div className={styles.contactSec}>
                            <div className={styles.contactSecBox}>
                                <form noValidate={false} onSubmit={handleSubmit}>
                                    <FormGroup className="customFormGroup">
                                        <p className={styles.didnotFind}>Didn’t find the solution?</p>
                                        <FormLabel className={`customFormLabel ${styles.contactText}`}>Contact us</FormLabel>
                                        {
                                            isSubmited ? <div className={styles.ThanksForChoose}>
                                                <h4>Our team will look into your query and get back to you in 24hr.</h4>
                                                <img src={thanksImg} alt="thanks image" />
                                                <p>Thanks for choosing AkosMD</p>
                                            </div>
                                                :
                                                <TextareaAutosize
                                                    minRows={5}
                                                    className={`custumTextField profileInput w-full`}
                                                    placeholder="Type your concern"
                                                    name="message"
                                                    required
                                                    value={message}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                />
                                        }
                                    </FormGroup>
                                    {
                                        !isSubmited &&
                                        <button type="submit" className="fillButton w-full mt-6" disabled={btnLoading}> {btnLoading && <BtnLoader />}SEND</button>
                                    }
                                    <hr className="hrLine" />
                                    <div className={styles.MailUsContactUs}>
                                        <p>Mail us at : akosmdtech@gmail.com</p>
                                        <p>Contact us : +9193384483783</p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </CustomContainer>
    )
}

export default Help
