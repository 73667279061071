import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import Cookies from "js-cookie";
import store from "../store";
import { clearAuth, setAuthToken } from "../store/auth/AuthSlice";
import { PATIENT_BASE_URL } from "./routes";

axios.defaults.baseURL = PATIENT_BASE_URL;
axios.defaults.headers.common["Content-Type"] = "application/json";
const axiosPatientAuth = axios.create();

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
}

axiosPatientAuth.interceptors.request.use(
  async (config: CustomAxiosRequestConfig) => {
    const token = Cookies.get("token");
    if (token) {
      config.headers = config.headers || {};
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["role"] = "patient";
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

axiosPatientAuth.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config as AxiosRequestConfig & {
      _retry?: boolean;
    };
    if (
      error.response?.status === 401 &&
      originalRequest &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      Cookies.remove('token');
      Cookies.remove('refreshToken');
      store.dispatch(clearAuth());
      // try {
      //   const refreshToken = Cookies.get("refreshToken");
      //   const { data } = await axiosPatientAuth.post("/refresh-token", {
      //     refreshToken,
      //   });
      //   const newToken = data.token;

      //   Cookies.set("token", newToken);
      //   store.dispatch(setAuthToken(newToken));
      //   axiosPatientAuth.defaults.headers.common[
      //     "Authorization"
      //   ] = `Bearer ${newToken}`;
      //   if (originalRequest.headers) {
      //     originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
      //   }
      //   return axiosPatientAuth(originalRequest);
      // } catch (refreshError) {
      //   console.error("Token refresh failed:", refreshError);
      //   Cookies.remove("token");
      //   Cookies.remove("refreshToken");
      //   store.dispatch(clearAuth());
      //   return Promise.reject(refreshError);
      // }
    }
    return Promise.reject(error);
  }
);

export default axiosPatientAuth;
